import React from 'react';
import LanguageProvider from './src/components/language';

// global styles are in strapi
import "./src/styles/animations.css";
import "@fancyapps/fancybox/dist/jquery.fancybox.css"
import "./src/fonts/fonts.css"
import 'swiper/swiper-bundle.css';

import $ from 'jquery';

export const onClientEntry = () => {
  window.$ = window.jQuery = require('jquery');
  window.fancy = require('@fancyapps/fancybox');

  $.fancybox.defaults.afterLoad = function () {
    var vid = document.querySelector(".fancybox-video");
    if (vid) {
      vid.setAttribute("controlsList", "nodownload noplaybackrate");
      vid.disablePictureInPicture = true;
      vid.addEventListener('ended',function(e) {
        $(".fancybox-container").fadeOut(300, function() { $(this).remove(); })
      }, false);
    }
  };

  $.fancybox.defaults.beforeClose = function () {
    $(".fancybox-container").fadeOut(300, function() { $(this).remove(); })
    return false;
  };

  window.Shuffle = require('shufflejs');

  document.addEventListener('contextmenu', event => event.preventDefault());
}


// export const wrapRootElement = ({ element }) => (
//   <LanguageProvider>{element}</LanguageProvider>
// );


export const wrapRootElement = LanguageProvider
